<template>
    <div class="examples-all">
		<!-- <div class="common-title examples-title">CustomizedPrints</div> -->
		<Examples :headTop="headTop" />
	</div>
</template>


<script>
    import '@/assets/less/CustomerExamples.less';
	import Examples from '@/components/Examples.vue';

	export default {
		name: 'CustomizedPrints',
		props: [
			'headTop'
		],
		components: {
			Examples
		},
		data() {
			return {}
		}
	}
</script>